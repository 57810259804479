import './login.scss';
import $ from "jquery";

$(function () {
    $('.form-switch').on('click', function() {
        $('.wrapper-form-password, .wrapper-form-connexion').toggleClass('disabled');
    })

    $("input[type='checkbox']").webfCheckbox({
        classWrapper: 'chk-remember'
    })

    $('#forgot-password').webfAjaxform({
        beforeSubmit: function() {
            $('.dialog-wait').webfDialog('open');
        },
        onSuccess: function(datas) {
            $('.form-response')
                .html('').removeClass('alert alert-success alert-danger')
                .html(datas.response).addClass(datas.status == 'ok' ? "alert alert-success" : "alert alert-danger")
            ;

            $('.dialog-wait').webfDialog('close');
        }
    });

    $('.password-first').on('focus', function () {
        $('.informations-password').removeClass('d-none');
    }).on('input', function () {
        let value = $(this).val();
        let nombreCaracteres = value.length;
        let chiffre = false;
        let lettre = false;
        let minuscule = false;
        let majuscule = false;

        let i = 0;
        while (i < nombreCaracteres){
            let caractere = value.charAt(i);

            if (!isNaN(caractere * 1)){
                chiffre = true;
            } else {
                lettre = true;

                if (caractere === caractere.toUpperCase()) {
                    majuscule = true;
                }
                if (caractere === caractere.toLowerCase()){
                    minuscule = true;
                }
            }
            i++;
        }

        if (nombreCaracteres > 0) {
            $('.validation-password').show();

            if (nombreCaracteres >= 8) {
                validationOk($('.informations-password .validation-longueur'));
            } else {
                validationFailed($('.informations-password .validation-longueur'));
            }
        } else {
            $('.validation-password').hide();
        }

        if (chiffre) {
            validationOk($('.informations-password .validation-chiffre'));
        } else {
            validationFailed($('.informations-password .validation-chiffre'));
        }

        if (lettre) {
            validationOk($('.informations-password .validation-lettre'));
        } else {
            validationFailed($('.informations-password .validation-lettre'));
        }

        if (minuscule) {
            validationOk($('.informations-password .validation-minuscule'));
        } else {
            validationFailed($('.informations-password .validation-minuscule'));
        }

        if (majuscule) {
            validationOk($('.informations-password .validation-majuscule'));
        } else {
            validationFailed($('.informations-password .validation-majuscule'));
        }
    });

    let validationOk = function ($selecteur) {
        $selecteur.removeClass('fa-times').addClass('fa-check').addClass('validation-ok');
    }

    let validationFailed = function ($selecteur) {
        $selecteur.removeClass('fa-check').removeClass('validation-ok').addClass('fa-times');
    }

    $('.password-eye-icons').on('click', '.password-hidden', function() {
        let $iconPasswordShown = $(this).closest('.password-eye-icons').find('.password-shown');
        let $input = $(this).closest('.input-group').find('input');

        $input.prop('type', 'text');

        $(this).hide();
        $iconPasswordShown.show();
    }).on('click', '.password-shown', function() {
        let $iconPasswordHidden = $(this).closest('.password-eye-icons').find('.password-hidden');
        let $input = $(this).closest('.input-group').find('input');

        $input.prop('type', 'password');

        $(this).hide();
        $iconPasswordHidden.show();
    });
});
